.reductions-options-container {
  .description {
    margin-left: 25px;
    font-size: 12px;

    p {
      margin: 5px 0;
    }
  }

  .stock {
    margin-left: 25px;
    font-size: 12px;
  }
}
