// Default styles.
:root {
  // Colors that can change w/ configuration from API. Here is the "default values".
  --color-primary: #5ab11a;
  --color-secondary: #101e67;
}

$color-primary: var(--color-primary);
$color-secondary: var(--color-secondary);
$color-danger: #e2001a;
$color-danger-light: #f08080;
$color-grey-light: #e8e8e8;
$color-grey-lighter: #ececec;
$color-grey: #737373;
$color-grey-dark: #cccccc;
$color-title: #150d26;
$color-white: #fff;
$color-black: #000;
$color-white-darken: darken($color-white, 5%);

/*
 * Screen breakpoints.
 */
$breakpoint-xs: 0;
$breakpoint-sm: 576px;
$breakpoint-md: 768px;
$breakpoint-lg: 992px;
$breakpoint-xl: 1200px;
$breakpoint-xxl: 1600px;

/*
 * Other properties.
 */
$header-height: 60px;
$footer-height: 30px;

/*
 * Fonts.
 */
$font-bebas: "BebasNeue-Bold";
$font-default: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
  "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;

@font-face {
  font-family: "BebasNeue";
  src: url("/assets/fonts/BebasNeue Regular.otf") format("truetype");
}

@font-face {
  font-family: "BebasNeue-Bold";
  src: url("/assets/fonts/BebasNeue Bold.ttf") format("truetype");
}

@font-face {
  font-family: "BebasNeue-Book";
  src: url("/assets/fonts/BebasNeue Book.otf") format("truetype");
}

@font-face {
  font-family: "BebasNeue-Light";
  src: url("/assets/fonts/BebasNeue Light.otf") format("truetype");
}

@font-face {
  font-family: "BebasNeue-Thin";
  src: url("/assets/fonts/BebasNeue Thin.otf") format("truetype");
}

%disabled {
  opacity: 0.8;
  background-color: $color-white-darken;
  cursor: not-allowed;
}

@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700&display=swap');