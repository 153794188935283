.information-licence-container {
  display: flex;
  flex-direction: column;

  &__title {
    font-weight: bold;
    margin-top: 25px;
    margin-bottom: 10px;
  }

  &__right {
    margin-bottom: 25px;
  }
}

.birthplace-autocomplete {
  display: flex;
  align-items: center;
  gap: 4px;

  svg {
    margin-top: 36px;
  }
}

@media (min-width: $breakpoint-lg) {
  .information-licence-container {
    flex-direction: row;
    align-items: center;

    &__left {
      min-width: 400px;
      margin-right: 25px;
    }

    &__right {
      margin-left: 25px;
    }
  }
}
