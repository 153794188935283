@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700&display=swap);
:root {
  --color-primary: #5ab11a;
  --color-secondary: #101e67;
}

/*
 * Screen breakpoints.
 */
/*
 * Other properties.
 */
/*
 * Fonts.
 */
@font-face {
  font-family: "BebasNeue";
  src: url("/assets/fonts/BebasNeue Regular.otf") format("truetype");
}
@font-face {
  font-family: "BebasNeue-Bold";
  src: url("/assets/fonts/BebasNeue Bold.ttf") format("truetype");
}
@font-face {
  font-family: "BebasNeue-Book";
  src: url("/assets/fonts/BebasNeue Book.otf") format("truetype");
}
@font-face {
  font-family: "BebasNeue-Light";
  src: url("/assets/fonts/BebasNeue Light.otf") format("truetype");
}
@font-face {
  font-family: "BebasNeue-Thin";
  src: url("/assets/fonts/BebasNeue Thin.otf") format("truetype");
}
.button {
  color: white;
  padding: 5px 20px;
  text-transform: uppercase;
  letter-spacing: 1px;
  transition: all 0.3s;
  cursor: pointer;
}
.button.button-primary {
  background-color: #5ab11a;
  background-color: var(--color-primary);
  border: 2px solid #5ab11a;
  border: 2px solid var(--color-primary);
}
.button.button-primary:hover:not(.button-loading) {
  background-color: #101e67;
  background-color: var(--color-secondary);
  border: 2px solid #101e67;
  border: 2px solid var(--color-secondary);
}
.button.button-secondary {
  background-color: transparent;
  color: #5ab11a;
  color: var(--color-primary);
  border: 2px solid #5ab11a;
  border: 2px solid var(--color-primary);
}
.button.button-secondary:hover:not(.button-loading) {
  color: #101e67;
  color: var(--color-secondary);
  border: 2px solid #101e67;
  border: 2px solid var(--color-secondary);
}
.button.button-danger {
  background-color: #e2001a;
  border: 2px solid #e2001a;
}
.button.button-danger:hover:not(.button-loading) {
  background-color: #101e67;
  background-color: var(--color-secondary);
  border: 2px solid #101e67;
  border: 2px solid var(--color-secondary);
}
.button:disabled {
  color: #737373;
  background-color: #d9d9d9;
  border: 2px solid #d9d9d9;
}
.button:disabled, .button.button-loading {
  cursor: not-allowed;
}
.button svg {
  margin-right: 5px;
}
.empty-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  opacity: 0.5;
}
.empty-container svg {
  font-size: 60px;
  opacity: 0.5;
}
.loading-container.loading-center {
  text-align: center;
}
.loading-container.loading-right {
  text-align: right;
}
.loading-container.loading-sm {
  font-size: 10px;
}
.loading-container.loading-md {
  font-size: 16px;
}
.loading-container.loading-lg {
  font-size: 22px;
}
.loading-container.loading-fullscreen {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: white;
}
:root {
  --color-primary: #5ab11a;
  --color-secondary: #101e67;
}

/*
 * Screen breakpoints.
 */
/*
 * Other properties.
 */
/*
 * Fonts.
 */
@font-face {
  font-family: "BebasNeue";
  src: url("/assets/fonts/BebasNeue Regular.otf") format("truetype");
}
@font-face {
  font-family: "BebasNeue-Bold";
  src: url("/assets/fonts/BebasNeue Bold.ttf") format("truetype");
}
@font-face {
  font-family: "BebasNeue-Book";
  src: url("/assets/fonts/BebasNeue Book.otf") format("truetype");
}
@font-face {
  font-family: "BebasNeue-Light";
  src: url("/assets/fonts/BebasNeue Light.otf") format("truetype");
}
@font-face {
  font-family: "BebasNeue-Thin";
  src: url("/assets/fonts/BebasNeue Thin.otf") format("truetype");
}
.notification-container {
  position: fixed;
  bottom: 25px !important;
  left: 50%;
  -webkit-transform: translateX(-50%) !important;
          transform: translateX(-50%) !important;
  width: -webkit-fit-content !important;
  width: -moz-fit-content !important;
  width: fit-content !important;
  height: 50px;
  padding: 5px 10px;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: #fff;
}
.notification-container.notification-danger {
  background-color: #e2001a;
}
.notification-container.notification-primary {
  background-color: #5ab11a;
  background-color: var(--color-primary);
  color: #fff;
}
.notification-container.notification-secondary {
  background-color: #101e67;
  background-color: var(--color-secondary);
}
.notification-container.active {
  -webkit-animation: display-animation 0.3s ease-in-out forwards;
          animation: display-animation 0.3s ease-in-out forwards;
}
.notification-container.hide {
  -webkit-animation: hide-animation 0.3s ease-in-out forwards;
          animation: hide-animation 0.3s ease-in-out forwards;
}
.notification-container svg {
  margin-right: 10px;
}

@-webkit-keyframes display-animation {
  from {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
  to {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

@keyframes display-animation {
  from {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
  to {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
@-webkit-keyframes hide-animation {
  from {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  to {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
}
@keyframes hide-animation {
  from {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  to {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
}
:root {
  --color-primary: #5ab11a;
  --color-secondary: #101e67;
}

/*
 * Screen breakpoints.
 */
/*
 * Other properties.
 */
/*
 * Fonts.
 */
@font-face {
  font-family: "BebasNeue";
  src: url("/assets/fonts/BebasNeue Regular.otf") format("truetype");
}
@font-face {
  font-family: "BebasNeue-Bold";
  src: url("/assets/fonts/BebasNeue Bold.ttf") format("truetype");
}
@font-face {
  font-family: "BebasNeue-Book";
  src: url("/assets/fonts/BebasNeue Book.otf") format("truetype");
}
@font-face {
  font-family: "BebasNeue-Light";
  src: url("/assets/fonts/BebasNeue Light.otf") format("truetype");
}
@font-face {
  font-family: "BebasNeue-Thin";
  src: url("/assets/fonts/BebasNeue Thin.otf") format("truetype");
}
.tab-container .tab-item {
  display: inline-block;
  border: 1px solid #5ab11a;
  border: 1px solid var(--color-primary);
  padding: 5px 70px;
  cursor: pointer;
  transition: all 0.3s ease;
  color: #101e67;
  color: var(--color-secondary);
  text-transform: uppercase;
  font-weight: bold;
}
.tab-container .tab-item:hover {
  border-color: #5ab11a;
  border-color: var(--color-primary);
  background-color: #5ab11a;
  background-color: var(--color-primary);
}
.tab-container .tab-item.tab-selected {
  color: white;
  background-color: #5ab11a;
  background-color: var(--color-primary);
  cursor: initial;
}
.tab-container .tab-item.tab-selected:hover {
  border-color: #101e67;
  border-color: var(--color-secondary);
  background-color: #101e67;
  background-color: var(--color-secondary);
}
:root {
  --color-primary: #5ab11a;
  --color-secondary: #101e67;
}

/*
 * Screen breakpoints.
 */
/*
 * Other properties.
 */
/*
 * Fonts.
 */
@font-face {
  font-family: "BebasNeue";
  src: url("/assets/fonts/BebasNeue Regular.otf") format("truetype");
}
@font-face {
  font-family: "BebasNeue-Bold";
  src: url("/assets/fonts/BebasNeue Bold.ttf") format("truetype");
}
@font-face {
  font-family: "BebasNeue-Book";
  src: url("/assets/fonts/BebasNeue Book.otf") format("truetype");
}
@font-face {
  font-family: "BebasNeue-Light";
  src: url("/assets/fonts/BebasNeue Light.otf") format("truetype");
}
@font-face {
  font-family: "BebasNeue-Thin";
  src: url("/assets/fonts/BebasNeue Thin.otf") format("truetype");
}
.table-container {
  width: 100%;
  border-collapse: collapse;
  border: 1px solid #e8e8e8;
}
.table-container thead {
  display: table;
  width: 100%;
  table-layout: fixed;
}
.table-container thead tr {
  border-bottom: 1px solid #e8e8e8;
}
.table-container thead tr th {
  background-color: #5ab11a;
  background-color: var(--color-primary);
  color: white;
  padding: 16px 16px;
  text-align: left;
  font-size: 14px;
  font-weight: 500;
}
.table-container thead tr th:first-child {
  border-top-left-radius: 4px;
}
.table-container thead tr th:last-child {
  border-top-right-radius: 4px;
}
.table-container thead tr th.sortable {
  cursor: pointer;
}
.table-container thead tr th .sort-icon {
  margin-right: 5px;
}
.table-container thead tr th.table-align-left {
  text-align: left;
}
.table-container thead tr th.table-align-center {
  text-align: center;
}
.table-container thead tr th.table-align-right {
  text-align: right;
}
.table-container tbody {
  display: block;
  overflow-y: auto;
}
.table-container tbody tr {
  display: table;
  width: 100%;
  table-layout: fixed;
  transition: all 0.3s;
}
.table-container tbody tr:hover:not(.empty):not(.loading) {
  background-color: #e8e8e8;
}
.table-container tbody tr.loading .s01 {
  width: 100%;
  height: 15px;
  background-color: #f2f2f2;
  margin-top: 15px;
  animation: skeleton-animation 2s ease infinite;
  -webkit-animation: skeleton-animation 2s ease infinite;
  -moz-animation: skeleton-animation 2s ease infinite;
}
.table-container tbody tr td {
  padding: 16px 16px;
  font-size: 14px;
}
.table-container tbody tr td.table-align-left {
  text-align: left;
}
.table-container tbody tr td.table-align-center {
  text-align: center;
}
.table-container tbody tr td.table-align-right {
  text-align: right;
}
.table-container.table-clickable tbody tr {
  cursor: pointer;
}
.table-container.table-empty thead tr th {
  opacity: 0.5;
}
.table-container.table-empty thead tr th.sortable {
  cursor: initial;
}
.table-container.table-empty thead tr th.sortable:hover {
  background-color: #fafafa;
}

@-webkit-keyframes skeleton-animation {
  0% {
    background-color: #f2f2f2;
  }
  30% {
    background-color: #e6e6e6;
  }
  100% {
    background-color: #f2f2f2;
  }
}
@keyframes skeleton-animation {
  0% {
    background-color: #f2f2f2;
  }
  30% {
    background-color: #e6e6e6;
  }
  100% {
    background-color: #f2f2f2;
  }
}
:root {
  --color-primary: #5ab11a;
  --color-secondary: #101e67;
}

/*
 * Screen breakpoints.
 */
/*
 * Other properties.
 */
/*
 * Fonts.
 */
@font-face {
  font-family: "BebasNeue";
  src: url("/assets/fonts/BebasNeue Regular.otf") format("truetype");
}
@font-face {
  font-family: "BebasNeue-Bold";
  src: url("/assets/fonts/BebasNeue Bold.ttf") format("truetype");
}
@font-face {
  font-family: "BebasNeue-Book";
  src: url("/assets/fonts/BebasNeue Book.otf") format("truetype");
}
@font-face {
  font-family: "BebasNeue-Light";
  src: url("/assets/fonts/BebasNeue Light.otf") format("truetype");
}
@font-face {
  font-family: "BebasNeue-Thin";
  src: url("/assets/fonts/BebasNeue Thin.otf") format("truetype");
}
.wizard-dot {
  position: relative;
  flex: 1 1;
  text-align: center;
  opacity: 0.2;
  font-size: 1.2rem;
  color: #5ab11a;
  color: var(--color-primary);
}
.wizard-dot.active {
  opacity: 1;
}
.wizard-dot.active .wizard-line {
  opacity: 1;
}
.wizard-dot[data-clickable=true] {
  cursor: pointer;
}
.wizard-dot .wizard-line {
  position: absolute;
  width: 90%;
  top: 13px;
  left: 55%;
  border-top: 1px solid #5ab11a;
  border-top: 1px solid var(--color-primary);
  opacity: 0.2;
}
:root {
  --color-primary: #5ab11a;
  --color-secondary: #101e67;
}

/*
 * Screen breakpoints.
 */
/*
 * Other properties.
 */
/*
 * Fonts.
 */
@font-face {
  font-family: "BebasNeue";
  src: url("/assets/fonts/BebasNeue Regular.otf") format("truetype");
}
@font-face {
  font-family: "BebasNeue-Bold";
  src: url("/assets/fonts/BebasNeue Bold.ttf") format("truetype");
}
@font-face {
  font-family: "BebasNeue-Book";
  src: url("/assets/fonts/BebasNeue Book.otf") format("truetype");
}
@font-face {
  font-family: "BebasNeue-Light";
  src: url("/assets/fonts/BebasNeue Light.otf") format("truetype");
}
@font-face {
  font-family: "BebasNeue-Thin";
  src: url("/assets/fonts/BebasNeue Thin.otf") format("truetype");
}
.wizard-title {
  flex: 1 1;
  text-align: center;
  font-size: 0.6rem;
}
.wizard-title.active {
  font-weight: bold;
}
.wizard-title[data-clickable=true] {
  cursor: pointer;
}

@media (min-width: 768px) {
  .wizard-title {
    font-size: 1rem;
  }
}
:root {
  --color-primary: #5ab11a;
  --color-secondary: #101e67;
}

/*
 * Screen breakpoints.
 */
/*
 * Other properties.
 */
/*
 * Fonts.
 */
@font-face {
  font-family: "BebasNeue";
  src: url("/assets/fonts/BebasNeue Regular.otf") format("truetype");
}
@font-face {
  font-family: "BebasNeue-Bold";
  src: url("/assets/fonts/BebasNeue Bold.ttf") format("truetype");
}
@font-face {
  font-family: "BebasNeue-Book";
  src: url("/assets/fonts/BebasNeue Book.otf") format("truetype");
}
@font-face {
  font-family: "BebasNeue-Light";
  src: url("/assets/fonts/BebasNeue Light.otf") format("truetype");
}
@font-face {
  font-family: "BebasNeue-Thin";
  src: url("/assets/fonts/BebasNeue Thin.otf") format("truetype");
}
.wizard-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.wizard-container.margin {
  margin-bottom: 20px;
}
:root {
  --color-primary: #5ab11a;
  --color-secondary: #101e67;
}

/*
 * Screen breakpoints.
 */
/*
 * Other properties.
 */
/*
 * Fonts.
 */
@font-face {
  font-family: "BebasNeue";
  src: url("/assets/fonts/BebasNeue Regular.otf") format("truetype");
}
@font-face {
  font-family: "BebasNeue-Bold";
  src: url("/assets/fonts/BebasNeue Bold.ttf") format("truetype");
}
@font-face {
  font-family: "BebasNeue-Book";
  src: url("/assets/fonts/BebasNeue Book.otf") format("truetype");
}
@font-face {
  font-family: "BebasNeue-Light";
  src: url("/assets/fonts/BebasNeue Light.otf") format("truetype");
}
@font-face {
  font-family: "BebasNeue-Thin";
  src: url("/assets/fonts/BebasNeue Thin.otf") format("truetype");
}
.step-title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  border-bottom: 1px solid #cccccc;
}
.step-title.collapsible {
  cursor: pointer;
}
.step-title h4 {
  margin: 0;
}
.step-title small {
  display: block;
  color: #737373;
  font-size: 0.8rem;
}
.step-title .collapsible-icon {
  font-size: 1.8rem;
  color: #5ab11a;
  color: var(--color-primary);
}

@media (min-width: 768px) {
  .step-title h4 {
    font-size: 2rem;
  }
  .step-title h4 small {
    font-size: 1.2rem;
  }
}
:root {
  --color-primary: #5ab11a;
  --color-secondary: #101e67;
}

/*
 * Screen breakpoints.
 */
/*
 * Other properties.
 */
/*
 * Fonts.
 */
@font-face {
  font-family: "BebasNeue";
  src: url("/assets/fonts/BebasNeue Regular.otf") format("truetype");
}
@font-face {
  font-family: "BebasNeue-Bold";
  src: url("/assets/fonts/BebasNeue Bold.ttf") format("truetype");
}
@font-face {
  font-family: "BebasNeue-Book";
  src: url("/assets/fonts/BebasNeue Book.otf") format("truetype");
}
@font-face {
  font-family: "BebasNeue-Light";
  src: url("/assets/fonts/BebasNeue Light.otf") format("truetype");
}
@font-face {
  font-family: "BebasNeue-Thin";
  src: url("/assets/fonts/BebasNeue Thin.otf") format("truetype");
}
.card-container {
  width: 92%;
  margin: auto;
  border: 1px solid #cccccc;
  margin-bottom: 10px;
}
.card-container .step-content {
  -webkit-transform-origin: 0% 50%;
          transform-origin: 0% 50%;
  transition: all 0.3s ease;
  font-size: 0;
  height: 0;
  overflow: hidden;
}
.card-container .step-content.visible {
  padding: 10px;
  height: initial;
  font-size: 1rem;
}
.card-container .step-content:not(.visible) {
  -webkit-animation: hide-content-animation 0.1s both;
  animation: hide-content-animation 0.1s both;
}
.card-container hr {
  border: none;
  border-bottom: 1px solid #cccccc;
  margin-top: 30px;
}

@media (min-width: 768px) {
  .card-container {
    width: 100%;
    margin-bottom: 40px;
  }
  .card-container .step-content {
    padding: 0;
  }
  .card-container .step-content.visible {
    padding: 40px;
  }
}
/**
 * ----------------------------------------
 * hide-content-animation
 * ----------------------------------------
 */
@-webkit-keyframes hide-content-animation {
  100% {
    visibility: hidden;
  }
}
@keyframes hide-content-animation {
  100% {
    visibility: hidden;
  }
}
:root {
  --color-primary: #5ab11a;
  --color-secondary: #101e67;
}

/*
 * Screen breakpoints.
 */
/*
 * Other properties.
 */
/*
 * Fonts.
 */
@font-face {
  font-family: "BebasNeue";
  src: url("/assets/fonts/BebasNeue Regular.otf") format("truetype");
}
@font-face {
  font-family: "BebasNeue-Bold";
  src: url("/assets/fonts/BebasNeue Bold.ttf") format("truetype");
}
@font-face {
  font-family: "BebasNeue-Book";
  src: url("/assets/fonts/BebasNeue Book.otf") format("truetype");
}
@font-face {
  font-family: "BebasNeue-Light";
  src: url("/assets/fonts/BebasNeue Light.otf") format("truetype");
}
@font-face {
  font-family: "BebasNeue-Thin";
  src: url("/assets/fonts/BebasNeue Thin.otf") format("truetype");
}
.dropzone-container {
  text-align: center;
}
.dropzone-container .dropzone-hidden-input {
  display: none;
}
.dropzone-container .dropzone {
  margin: auto;
  width: 250px;
  height: 150px;
  background-color: #ebf6ff;
  padding: 15px;
  border-radius: 5px;
  border: 1px solid transparent;
  cursor: pointer;
  transition: border-color 0.3s ease;
}
.dropzone-container .dropzone .dropzone-content {
  width: 100%;
  height: 100%;
  border: 1px dashed #63b2f5;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.dropzone-container .dropzone .dropzone-content .wrap-long-text {
  padding: 5px;
}
.dropzone-container .dropzone .dropzone-content .dropzone-plus-sign {
  color: #63b2f5;
  font-size: 2rem;
  transition: -webkit-transform 0.2s ease;
  transition: transform 0.2s ease;
  transition: transform 0.2s ease, -webkit-transform 0.2s ease;
}
.dropzone-container .dropzone:hover {
  border-color: #5ab11a;
  border-color: var(--color-primary);
}
.dropzone-container .dropzone:hover .dropzone-content .dropzone-plus-sign {
  -webkit-transform: rotateZ(180deg);
          transform: rotateZ(180deg);
}
.dropzone-container .explain-text {
  font-size: 0.8rem;
  font-style: italic;
}
.dropzone-container .dropzone-error {
  color: #e2001a;
}
:root {
  --color-primary: #5ab11a;
  --color-secondary: #101e67;
}

/*
 * Screen breakpoints.
 */
/*
 * Other properties.
 */
/*
 * Fonts.
 */
@font-face {
  font-family: "BebasNeue";
  src: url("/assets/fonts/BebasNeue Regular.otf") format("truetype");
}
@font-face {
  font-family: "BebasNeue-Bold";
  src: url("/assets/fonts/BebasNeue Bold.ttf") format("truetype");
}
@font-face {
  font-family: "BebasNeue-Book";
  src: url("/assets/fonts/BebasNeue Book.otf") format("truetype");
}
@font-face {
  font-family: "BebasNeue-Light";
  src: url("/assets/fonts/BebasNeue Light.otf") format("truetype");
}
@font-face {
  font-family: "BebasNeue-Thin";
  src: url("/assets/fonts/BebasNeue Thin.otf") format("truetype");
}
.display-pricing--price-item .reductions-options-container .content, .display-pricing--price-item .flex-container {
  border-bottom: 1px solid #cccccc;
}

.display-pricing--price-item {
  font-size: 18px;
}
.display-pricing--price-item .flex-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 15px;
}
.display-pricing--price-item .flex-container > div:nth-child(1) {
  flex: 6 1;
  font-weight: bold;
}
.display-pricing--price-item .flex-container > div:nth-child(2) {
  flex: 1 1;
}
.display-pricing--price-item .flex-container > div:nth-child(3) {
  flex: 1 1;
  text-align: center;
}
.display-pricing--price-item .reductions-options-container {
  overflow: hidden;
}
.display-pricing--price-item .reductions-options-container .content {
  transition: all 0.3s;
  padding: 25px;
  background-color: #ececec;
}
.display-pricing--price-item .reductions-options-container .content div:last-child h4 {
  margin-top: 25px;
}
.display-pricing--price-item .reductions-options-container .content h4,
.display-pricing--price-item .reductions-options-container .content h5 {
  margin: 0;
}
.display-pricing--price-item .reductions-options-container .content h5 {
  font-size: 20px;
  margin-bottom: 10px;
}
.display-pricing--price-item .reductions-options-container[data-visible=true] .content {
  margin: 0;
}
.display-pricing--price-item .reductions-options-container[data-visible=false] .content {
  margin-top: -100%;
}
.display-pricing--price-item-description {
  font-size: 14px;
  font-weight: initial;
}
.display-pricing .file-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.display-pricing .file-container--text {
  padding-right: 5px;
  color: #e2001a;
}

:root {
  --color-primary: #5ab11a;
  --color-secondary: #101e67;
}

/*
 * Screen breakpoints.
 */
/*
 * Other properties.
 */
/*
 * Fonts.
 */
@font-face {
  font-family: "BebasNeue";
  src: url("/assets/fonts/BebasNeue Regular.otf") format("truetype");
}
@font-face {
  font-family: "BebasNeue-Bold";
  src: url("/assets/fonts/BebasNeue Bold.ttf") format("truetype");
}
@font-face {
  font-family: "BebasNeue-Book";
  src: url("/assets/fonts/BebasNeue Book.otf") format("truetype");
}
@font-face {
  font-family: "BebasNeue-Light";
  src: url("/assets/fonts/BebasNeue Light.otf") format("truetype");
}
@font-face {
  font-family: "BebasNeue-Thin";
  src: url("/assets/fonts/BebasNeue Thin.otf") format("truetype");
}
.reductions-options-container--item {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.reductions-options-container--item > div:nth-child(1) {
  flex: 6 1;
}
.reductions-options-container--item > div:not(:nth-child(1)) {
  flex: 1 1;
}
.reductions-options-container--info {
  margin-left: 10px;
  color: #5ab11a;
  color: var(--color-primary);
}

.reductions-options-container .description {
  margin-left: 25px;
  font-size: 12px;
}
.reductions-options-container .description p {
  margin: 5px 0;
}
.reductions-options-container .stock {
  margin-left: 25px;
  font-size: 12px;
}

.display-proofs {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}
.display-proofs__item {
  position: relative;
  display: flex;
  flex-direction: column;
  border: 1px solid #737373;
  padding: 10px;
  margin-top: 15px;
}
.display-proofs__item[data-has-file=false] {
  cursor: pointer;
}
.display-proofs__item::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #5ab11a;
  background-color: var(--color-primary);
  opacity: 0;
  z-index: -1;
  transition: all 0.5s;
}
.display-proofs__item[data-has-file=false]:hover::before {
  opacity: 0.3;
}
.display-proofs__item:not(:last-child) {
  margin-right: 15px;
}
.display-proofs__item p {
  margin: 0;
  min-height: 42px;
}
.display-proofs--name {
  font-family: "BebasNeue-Bold";
  margin: 0;
}
.display-proofs--name small {
  color: #e2001a;
  font-size: 15px;
}
.display-proofs--description {
  margin-bottom: 10px;
}
.display-proofs--file {
  display: none;
}
.display-proofs--add-button {
  display: inline-block;
  text-align: center;
  width: 25px;
  height: 25px;
  line-height: 25px;
  text-align: center;
  background-color: #5ab11a;
  background-color: var(--color-primary);
  color: #fff;
  border-radius: 25px;
  margin: auto;
}
.display-proofs--remove-button {
  text-align: center;
  color: #e2001a;
  cursor: pointer;
}
.display-proofs--uploaded-file {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}

@media (min-width: 576px) {
  .display-proofs {
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }
  .display-proofs__item {
    width: 33%;
  }
}
.display-pricing__listing {
  display: flex;
  flex-direction: column;
  border: 1px solid #cccccc;
}
:root {
  --color-primary: #5ab11a;
  --color-secondary: #101e67;
}

/*
 * Screen breakpoints.
 */
/*
 * Other properties.
 */
/*
 * Fonts.
 */
@font-face {
  font-family: "BebasNeue";
  src: url("/assets/fonts/BebasNeue Regular.otf") format("truetype");
}
@font-face {
  font-family: "BebasNeue-Bold";
  src: url("/assets/fonts/BebasNeue Bold.ttf") format("truetype");
}
@font-face {
  font-family: "BebasNeue-Book";
  src: url("/assets/fonts/BebasNeue Book.otf") format("truetype");
}
@font-face {
  font-family: "BebasNeue-Light";
  src: url("/assets/fonts/BebasNeue Light.otf") format("truetype");
}
@font-face {
  font-family: "BebasNeue-Thin";
  src: url("/assets/fonts/BebasNeue Thin.otf") format("truetype");
}
.input-radio.disabled {
  opacity: 0.8;
  background-color: #f2f2f2;
  cursor: not-allowed;
}

.input-radio {
  display: inline;
  padding: 8px 10px;
  border-radius: 5px;
  border: 1px solid #cccccc;
  cursor: pointer;
  transition: border-color 0.5s ease;
}
.input-radio .icon {
  margin-right: 10px;
  color: #5ab11a;
  color: var(--color-primary);
  opacity: 0.4;
}
.input-radio.checked {
  border-color: #5ab11a;
  border-color: var(--color-primary);
}
.input-radio.checked .icon {
  opacity: 1;
}
.input-radio.input-error {
  border-color: #e2001a;
}
.input-radio:hover:not(.disabled) {
  border-color: #5ab11a;
  border-color: var(--color-primary);
}
:root {
  --color-primary: #5ab11a;
  --color-secondary: #101e67;
}

/*
 * Screen breakpoints.
 */
/*
 * Other properties.
 */
/*
 * Fonts.
 */
@font-face {
  font-family: "BebasNeue";
  src: url("/assets/fonts/BebasNeue Regular.otf") format("truetype");
}
@font-face {
  font-family: "BebasNeue-Bold";
  src: url("/assets/fonts/BebasNeue Bold.ttf") format("truetype");
}
@font-face {
  font-family: "BebasNeue-Book";
  src: url("/assets/fonts/BebasNeue Book.otf") format("truetype");
}
@font-face {
  font-family: "BebasNeue-Light";
  src: url("/assets/fonts/BebasNeue Light.otf") format("truetype");
}
@font-face {
  font-family: "BebasNeue-Thin";
  src: url("/assets/fonts/BebasNeue Thin.otf") format("truetype");
}
.form-error {
  color: #e2001a;
  font-size: 0.7rem;
}
:root {
  --color-primary: #5ab11a;
  --color-secondary: #101e67;
}

/*
 * Screen breakpoints.
 */
/*
 * Other properties.
 */
/*
 * Fonts.
 */
@font-face {
  font-family: "BebasNeue";
  src: url("/assets/fonts/BebasNeue Regular.otf") format("truetype");
}
@font-face {
  font-family: "BebasNeue-Bold";
  src: url("/assets/fonts/BebasNeue Bold.ttf") format("truetype");
}
@font-face {
  font-family: "BebasNeue-Book";
  src: url("/assets/fonts/BebasNeue Book.otf") format("truetype");
}
@font-face {
  font-family: "BebasNeue-Light";
  src: url("/assets/fonts/BebasNeue Light.otf") format("truetype");
}
@font-face {
  font-family: "BebasNeue-Thin";
  src: url("/assets/fonts/BebasNeue Thin.otf") format("truetype");
}
.input-radio-group-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}
.input-radio-group-container .input-radio {
  margin-right: 5px;
  margin-bottom: 5px;
}
.input-radio-group-container[data-color=danger] {
  color: #e2001a;
  font-weight: bold;
}

@media (min-width: 768px) {
  .input-radio-group-container {
    justify-content: initial;
  }
}
.input-label {
  display: block;
  margin: 20px 0 0 0;
  font-weight: 500;
}
.input-label--sub-label {
  display: block;
  margin-bottom: 10px;
}
:root {
  --color-primary: #5ab11a;
  --color-secondary: #101e67;
}

/*
 * Screen breakpoints.
 */
/*
 * Other properties.
 */
/*
 * Fonts.
 */
@font-face {
  font-family: "BebasNeue";
  src: url("/assets/fonts/BebasNeue Regular.otf") format("truetype");
}
@font-face {
  font-family: "BebasNeue-Bold";
  src: url("/assets/fonts/BebasNeue Bold.ttf") format("truetype");
}
@font-face {
  font-family: "BebasNeue-Book";
  src: url("/assets/fonts/BebasNeue Book.otf") format("truetype");
}
@font-face {
  font-family: "BebasNeue-Light";
  src: url("/assets/fonts/BebasNeue Light.otf") format("truetype");
}
@font-face {
  font-family: "BebasNeue-Thin";
  src: url("/assets/fonts/BebasNeue Thin.otf") format("truetype");
}
.form-input input:disabled,
.form-input input[type=text]:disabled,
.form-input input[type=number]:disabled,
.form-input input[type=date]:disabled,
.form-input input[type=tel]:disabled,
.form-input input[type=email]:disabled {
  opacity: 0.8;
  background-color: #f2f2f2;
  cursor: not-allowed;
}

.form-input input,
.form-input input[type=text],
.form-input input[type=number],
.form-input input[type=date],
.form-input input[type=tel],
.form-input input[type=email] {
  min-width: 250px;
  border: 1px solid #cccccc;
  padding: 10px;
  border-radius: 5px;
  transition: border-color 0.5s ease;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
}
.form-input input:focus,
.form-input input[type=text]:focus,
.form-input input[type=number]:focus,
.form-input input[type=date]:focus,
.form-input input[type=tel]:focus,
.form-input input[type=email]:focus {
  outline: none;
  border-color: #5ab11a;
  border-color: var(--color-primary);
}
.form-input input.input-error,
.form-input input[type=text].input-error,
.form-input input[type=number].input-error,
.form-input input[type=date].input-error,
.form-input input[type=tel].input-error,
.form-input input[type=email].input-error {
  border-color: #e2001a;
}
.form-input input:hover:not([disabled]),
.form-input input[type=text]:hover:not([disabled]),
.form-input input[type=number]:hover:not([disabled]),
.form-input input[type=date]:hover:not([disabled]),
.form-input input[type=tel]:hover:not([disabled]),
.form-input input[type=email]:hover:not([disabled]) {
  border-color: #5ab11a;
  border-color: var(--color-primary);
}
@media (min-width: 768px) {
  .form-input input[type=text].input-fullwidth,
.form-input input[type=date].input-fullwidth,
.form-input input[type=tel].input-fullwidth,
.form-input input[type=email].input-fullwidth {
    width: 100%;
  }
}
:root {
  --color-primary: #5ab11a;
  --color-secondary: #101e67;
}

/*
 * Screen breakpoints.
 */
/*
 * Other properties.
 */
/*
 * Fonts.
 */
@font-face {
  font-family: "BebasNeue";
  src: url("/assets/fonts/BebasNeue Regular.otf") format("truetype");
}
@font-face {
  font-family: "BebasNeue-Bold";
  src: url("/assets/fonts/BebasNeue Bold.ttf") format("truetype");
}
@font-face {
  font-family: "BebasNeue-Book";
  src: url("/assets/fonts/BebasNeue Book.otf") format("truetype");
}
@font-face {
  font-family: "BebasNeue-Light";
  src: url("/assets/fonts/BebasNeue Light.otf") format("truetype");
}
@font-face {
  font-family: "BebasNeue-Thin";
  src: url("/assets/fonts/BebasNeue Thin.otf") format("truetype");
}
.form-select select:disabled {
  opacity: 0.8;
  background-color: #f2f2f2;
  cursor: not-allowed;
}

.form-select select {
  min-width: 272px;
  border: 1px solid #cccccc;
  border-radius: 5px;
  transition: border-color 0.5s ease;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  height: 38.5px;
  background-color: #fff;
}
.form-select select:focus {
  outline: none;
  border-color: #5ab11a;
  border-color: var(--color-primary);
}
.form-select select.input-error {
  border-color: #e2001a;
}
.form-select select:hover:not([disabled]) {
  border-color: #5ab11a;
  border-color: var(--color-primary);
}
:root {
  --color-primary: #5ab11a;
  --color-secondary: #101e67;
}

/*
 * Screen breakpoints.
 */
/*
 * Other properties.
 */
/*
 * Fonts.
 */
@font-face {
  font-family: "BebasNeue";
  src: url("/assets/fonts/BebasNeue Regular.otf") format("truetype");
}
@font-face {
  font-family: "BebasNeue-Bold";
  src: url("/assets/fonts/BebasNeue Bold.ttf") format("truetype");
}
@font-face {
  font-family: "BebasNeue-Book";
  src: url("/assets/fonts/BebasNeue Book.otf") format("truetype");
}
@font-face {
  font-family: "BebasNeue-Light";
  src: url("/assets/fonts/BebasNeue Light.otf") format("truetype");
}
@font-face {
  font-family: "BebasNeue-Thin";
  src: url("/assets/fonts/BebasNeue Thin.otf") format("truetype");
}
.checkbox-container {
  transition: color 0.3s ease;
  color: #cccccc;
  cursor: not-allowed;
}
.checkbox-container:not(.disabled) {
  cursor: pointer;
  color: #5ab11a;
  color: var(--color-primary);
}
.checkbox-container:not(.disabled):hover {
  color: #101e67;
  color: var(--color-secondary);
}
:root {
  --color-primary: #5ab11a;
  --color-secondary: #101e67;
}

/*
 * Screen breakpoints.
 */
/*
 * Other properties.
 */
/*
 * Fonts.
 */
@font-face {
  font-family: "BebasNeue";
  src: url("/assets/fonts/BebasNeue Regular.otf") format("truetype");
}
@font-face {
  font-family: "BebasNeue-Bold";
  src: url("/assets/fonts/BebasNeue Bold.ttf") format("truetype");
}
@font-face {
  font-family: "BebasNeue-Book";
  src: url("/assets/fonts/BebasNeue Book.otf") format("truetype");
}
@font-face {
  font-family: "BebasNeue-Light";
  src: url("/assets/fonts/BebasNeue Light.otf") format("truetype");
}
@font-face {
  font-family: "BebasNeue-Thin";
  src: url("/assets/fonts/BebasNeue Thin.otf") format("truetype");
}
.checkbox-label-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding: 5px 0;
}
.checkbox-label-container label {
  margin-left: 10px;
}
.checkbox-label-container[data-color=danger] {
  color: #e2001a;
  font-weight: bold;
}
.checkbox-label-container[data-align=center] {
  align-items: center;
}
.checkbox-label-container[data-align=top] {
  align-items: top;
}
:root {
  --color-primary: #5ab11a;
  --color-secondary: #101e67;
}

/*
 * Screen breakpoints.
 */
/*
 * Other properties.
 */
/*
 * Fonts.
 */
@font-face {
  font-family: "BebasNeue";
  src: url("/assets/fonts/BebasNeue Regular.otf") format("truetype");
}
@font-face {
  font-family: "BebasNeue-Bold";
  src: url("/assets/fonts/BebasNeue Bold.ttf") format("truetype");
}
@font-face {
  font-family: "BebasNeue-Book";
  src: url("/assets/fonts/BebasNeue Book.otf") format("truetype");
}
@font-face {
  font-family: "BebasNeue-Light";
  src: url("/assets/fonts/BebasNeue Light.otf") format("truetype");
}
@font-face {
  font-family: "BebasNeue-Thin";
  src: url("/assets/fonts/BebasNeue Thin.otf") format("truetype");
}
.stepper-container {
  width: 120px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.stepper-container .icon {
  color: #5ab11a;
  color: var(--color-primary);
  cursor: pointer;
  font-size: 25px;
}
.stepper-container .icon[data-enabled=true] {
  opacity: 1;
  cursor: pointer;
}
.stepper-container .icon[data-enabled=false] {
  opacity: 0.2;
  cursor: not-allowed;
}
.stepper-container .stepper-value {
  display: inline-block;
  min-width: 25px;
  margin: 0 10px;
  border: 1px solid #e8e8e8;
  padding: 5px;
  text-align: center;
  -webkit-user-select: none;
          user-select: none;
}
:root {
  --color-primary: #5ab11a;
  --color-secondary: #101e67;
}

/*
 * Screen breakpoints.
 */
/*
 * Other properties.
 */
/*
 * Fonts.
 */
@font-face {
  font-family: "BebasNeue";
  src: url("/assets/fonts/BebasNeue Regular.otf") format("truetype");
}
@font-face {
  font-family: "BebasNeue-Bold";
  src: url("/assets/fonts/BebasNeue Bold.ttf") format("truetype");
}
@font-face {
  font-family: "BebasNeue-Book";
  src: url("/assets/fonts/BebasNeue Book.otf") format("truetype");
}
@font-face {
  font-family: "BebasNeue-Light";
  src: url("/assets/fonts/BebasNeue Light.otf") format("truetype");
}
@font-face {
  font-family: "BebasNeue-Thin";
  src: url("/assets/fonts/BebasNeue Thin.otf") format("truetype");
}
.multiselect__items {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-top: 10px;
}
.multiselect__item {
  padding: 0 5px;
  border-radius: 3px;
  border: 1px solid #5ab11a;
  border: 1px solid var(--color-primary);
  margin-right: 5px;
  font-size: 12px;
}
.multiselect__item > span {
  margin-right: 15px;
}
.multiselect__item--icon {
  cursor: pointer;
  transition: 0.3s ease;
}
.multiselect__item--icon:hover {
  color: #e2001a;
}
:root {
  --color-primary: #5ab11a;
  --color-secondary: #101e67;
}

/*
 * Screen breakpoints.
 */
/*
 * Other properties.
 */
/*
 * Fonts.
 */
@font-face {
  font-family: "BebasNeue";
  src: url("/assets/fonts/BebasNeue Regular.otf") format("truetype");
}
@font-face {
  font-family: "BebasNeue-Bold";
  src: url("/assets/fonts/BebasNeue Bold.ttf") format("truetype");
}
@font-face {
  font-family: "BebasNeue-Book";
  src: url("/assets/fonts/BebasNeue Book.otf") format("truetype");
}
@font-face {
  font-family: "BebasNeue-Light";
  src: url("/assets/fonts/BebasNeue Light.otf") format("truetype");
}
@font-face {
  font-family: "BebasNeue-Thin";
  src: url("/assets/fonts/BebasNeue Thin.otf") format("truetype");
}
.switch--dot, .switch {
  border-radius: 50px;
}

.switch--dot, .switch {
  transition: all 0.3s;
}

.switch {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 50px;
  height: 25px;
  border: 1px solid #696969;
  overflow: hidden;
  cursor: pointer;
  background-color: #f08080;
  opacity: 1;
  margin: 0 10px;
}
.switch--dot {
  position: absolute;
  width: 25px;
  height: 25px;
  background-color: #fff;
  border-color: #696969;
}
.switch[data-on=true] {
  background-color: #5ab11a;
  background-color: var(--color-primary);
}
.switch[data-on=true] .switch--dot {
  -webkit-transform: translateX(24px);
          transform: translateX(24px);
}
.switch[data-on=false]:not([data-disabled=true]) .switch--dot {
  -webkit-transform: translateX(1px);
          transform: translateX(1px);
}
.switch[data-disabled=true] {
  opacity: 0.4;
  cursor: not-allowed;
  background-color: #737373;
}
:root {
  --color-primary: #5ab11a;
  --color-secondary: #101e67;
}

/*
 * Screen breakpoints.
 */
/*
 * Other properties.
 */
/*
 * Fonts.
 */
@font-face {
  font-family: "BebasNeue";
  src: url("/assets/fonts/BebasNeue Regular.otf") format("truetype");
}
@font-face {
  font-family: "BebasNeue-Bold";
  src: url("/assets/fonts/BebasNeue Bold.ttf") format("truetype");
}
@font-face {
  font-family: "BebasNeue-Book";
  src: url("/assets/fonts/BebasNeue Book.otf") format("truetype");
}
@font-face {
  font-family: "BebasNeue-Light";
  src: url("/assets/fonts/BebasNeue Light.otf") format("truetype");
}
@font-face {
  font-family: "BebasNeue-Thin";
  src: url("/assets/fonts/BebasNeue Thin.otf") format("truetype");
}
.error-page {
  height: 100vh;
}
.error-page__content {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.error-page--logo {
  width: 200px;
}
.error-page--title {
  color: #5ab11a;
  color: var(--color-primary);
  margin-top: 12px;
}
.error-page--text {
  margin-top: -15px;
}
:root {
  --color-primary: #5ab11a;
  --color-secondary: #101e67;
}

/*
 * Screen breakpoints.
 */
/*
 * Other properties.
 */
/*
 * Fonts.
 */
@font-face {
  font-family: "BebasNeue";
  src: url("/assets/fonts/BebasNeue Regular.otf") format("truetype");
}
@font-face {
  font-family: "BebasNeue-Bold";
  src: url("/assets/fonts/BebasNeue Bold.ttf") format("truetype");
}
@font-face {
  font-family: "BebasNeue-Book";
  src: url("/assets/fonts/BebasNeue Book.otf") format("truetype");
}
@font-face {
  font-family: "BebasNeue-Light";
  src: url("/assets/fonts/BebasNeue Light.otf") format("truetype");
}
@font-face {
  font-family: "BebasNeue-Thin";
  src: url("/assets/fonts/BebasNeue Thin.otf") format("truetype");
}
.licence-renewal-header {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  padding: 5px;
  background: url(/assets/images/ffbad-maillot.jpg) no-repeat center center;
  background-size: cover;
  border-bottom: 5px solid #5ab11a;
  border-bottom: 5px solid var(--color-primary);
}
.licence-renewal-header .title {
  font-family: "BebasNeue-Bold";
  color: #fff;
}
.licence-renewal-header .club-logo-container {
  display: flex;
  align-items: center;
}
.licence-renewal-header .club-logo-container .ffbad-logo {
  width: 10rem;
  height: auto;
  margin-right: 1rem;
}
.licence-renewal-header .club-logo-container .club-logo {
  border-radius: 50%;
}

.fullscreen-image {
  width: 100%;
  height: 120px;
  background-image: url(/assets/images/renewal_background.png);
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 1.4rem;
  text-transform: uppercase;
  color: white;
  font-family: "BebasNeue-Bold";
  margin-bottom: 20px;
}

@media (min-width: 768px) {
  .licence-renewal-header {
    padding: 16px;
  }
  .licence-renewal-header .title {
    font-size: 3rem;
  }
  .fullscreen-image {
    height: 120px;
    font-size: 2.4rem;
    background-position-y: -250px;
  }
}
:root {
  --color-primary: #5ab11a;
  --color-secondary: #101e67;
}

/*
 * Screen breakpoints.
 */
/*
 * Other properties.
 */
/*
 * Fonts.
 */
@font-face {
  font-family: "BebasNeue";
  src: url("/assets/fonts/BebasNeue Regular.otf") format("truetype");
}
@font-face {
  font-family: "BebasNeue-Bold";
  src: url("/assets/fonts/BebasNeue Bold.ttf") format("truetype");
}
@font-face {
  font-family: "BebasNeue-Book";
  src: url("/assets/fonts/BebasNeue Book.otf") format("truetype");
}
@font-face {
  font-family: "BebasNeue-Light";
  src: url("/assets/fonts/BebasNeue Light.otf") format("truetype");
}
@font-face {
  font-family: "BebasNeue-Thin";
  src: url("/assets/fonts/BebasNeue Thin.otf") format("truetype");
}
.licence-chooser-container .items-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.licence-chooser-container .items-container .item {
  width: 120px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  margin-right: 10px;
  border: 1px solid #cccccc;
  transition: all 0.3s ease;
  cursor: pointer;
}
.licence-chooser-container .items-container .item:last-child {
  margin-right: 0;
}
.licence-chooser-container .items-container .item[data-selected=true] {
  background-color: #5ab11a;
  background-color: var(--color-primary);
  color: white;
  cursor: initial;
}
.licence-chooser-container .items-container .item:hover {
  border-color: #5ab11a;
  border-color: var(--color-primary);
}
.licence-chooser-container .items-container .item .icon {
  font-size: 25px;
}
:root {
  --color-primary: #5ab11a;
  --color-secondary: #101e67;
}

/*
 * Screen breakpoints.
 */
/*
 * Other properties.
 */
/*
 * Fonts.
 */
@font-face {
  font-family: "BebasNeue";
  src: url("/assets/fonts/BebasNeue Regular.otf") format("truetype");
}
@font-face {
  font-family: "BebasNeue-Bold";
  src: url("/assets/fonts/BebasNeue Bold.ttf") format("truetype");
}
@font-face {
  font-family: "BebasNeue-Book";
  src: url("/assets/fonts/BebasNeue Book.otf") format("truetype");
}
@font-face {
  font-family: "BebasNeue-Light";
  src: url("/assets/fonts/BebasNeue Light.otf") format("truetype");
}
@font-face {
  font-family: "BebasNeue-Thin";
  src: url("/assets/fonts/BebasNeue Thin.otf") format("truetype");
}
.avatar-container {
  border-bottom: 1px solid #cccccc;
  padding-bottom: 20px;
}
.avatar-container .content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.avatar-container .content input[type=file] {
  display: none;
}
.avatar-container .content .column-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 20px;
}
.avatar-container .content .column-container .title {
  font-weight: 500;
  text-transform: uppercase;
  font-size: 0.7rem;
}
.avatar-container .content .column-container .licence {
  color: #737373;
}
.avatar-container--picture {
  width: 80px;
  height: 80px;
  border: 1px solid #cccccc;
  transition: border-color 0.3s ease;
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  cursor: pointer;
}
.avatar-container--picture:hover {
  border-color: #5ab11a;
  border-color: var(--color-primary);
}

@media (min-width: 768px) {
  .avatar-container .content {
    width: 50%;
  }
  .avatar-container .content .column-container .title {
    font-size: 1rem;
  }
}
.form-flex-item--flex-1 {
  flex: 1 1;
}
.form-flex-item--flex-1 input {
  width: 92%;
}
:root {
  --color-primary: #5ab11a;
  --color-secondary: #101e67;
}

/*
 * Screen breakpoints.
 */
/*
 * Other properties.
 */
/*
 * Fonts.
 */
@font-face {
  font-family: "BebasNeue";
  src: url("/assets/fonts/BebasNeue Regular.otf") format("truetype");
}
@font-face {
  font-family: "BebasNeue-Bold";
  src: url("/assets/fonts/BebasNeue Bold.ttf") format("truetype");
}
@font-face {
  font-family: "BebasNeue-Book";
  src: url("/assets/fonts/BebasNeue Book.otf") format("truetype");
}
@font-face {
  font-family: "BebasNeue-Light";
  src: url("/assets/fonts/BebasNeue Light.otf") format("truetype");
}
@font-face {
  font-family: "BebasNeue-Thin";
  src: url("/assets/fonts/BebasNeue Thin.otf") format("truetype");
}
.modal {
  position: fixed;
  left: 0;
  bottom: 0;
}
.modal__box {
  border-color: #5ab11a;
  border-color: var(--color-primary);
}
.modal__heading--times-icon {
  color: #5ab11a;
  color: var(--color-primary);
}
.modal__content {
  padding-top: 5px;
}
.modal__footer .editor-button {
  background-color: #5ab11a !important;
  background-color: var(--color-primary) !important;
  border-color: #5ab11a !important;
  border-color: var(--color-primary) !important;
}

@media (min-width: 576px) {
  .modal__content {
    max-height: 400px;
  }
}
:root {
  --color-primary: #5ab11a;
  --color-secondary: #101e67;
}

/*
 * Screen breakpoints.
 */
/*
 * Other properties.
 */
/*
 * Fonts.
 */
@font-face {
  font-family: "BebasNeue";
  src: url("/assets/fonts/BebasNeue Regular.otf") format("truetype");
}
@font-face {
  font-family: "BebasNeue-Bold";
  src: url("/assets/fonts/BebasNeue Bold.ttf") format("truetype");
}
@font-face {
  font-family: "BebasNeue-Book";
  src: url("/assets/fonts/BebasNeue Book.otf") format("truetype");
}
@font-face {
  font-family: "BebasNeue-Light";
  src: url("/assets/fonts/BebasNeue Light.otf") format("truetype");
}
@font-face {
  font-family: "BebasNeue-Thin";
  src: url("/assets/fonts/BebasNeue Thin.otf") format("truetype");
}
.licence-renewal-footer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 60px 0;
  padding: 0 40px;
}
.licence-renewal-footer .link {
  color: black;
  text-transform: uppercase;
  font-weight: 600;
  text-decoration: underline;
  cursor: pointer;
}
.licence-renewal-footer button {
  padding: 10px 15px;
}
:root {
  --color-primary: #5ab11a;
  --color-secondary: #101e67;
}

/*
 * Screen breakpoints.
 */
/*
 * Other properties.
 */
/*
 * Fonts.
 */
@font-face {
  font-family: "BebasNeue";
  src: url("/assets/fonts/BebasNeue Regular.otf") format("truetype");
}
@font-face {
  font-family: "BebasNeue-Bold";
  src: url("/assets/fonts/BebasNeue Bold.ttf") format("truetype");
}
@font-face {
  font-family: "BebasNeue-Book";
  src: url("/assets/fonts/BebasNeue Book.otf") format("truetype");
}
@font-face {
  font-family: "BebasNeue-Light";
  src: url("/assets/fonts/BebasNeue Light.otf") format("truetype");
}
@font-face {
  font-family: "BebasNeue-Thin";
  src: url("/assets/fonts/BebasNeue Thin.otf") format("truetype");
}
.information-licence-container {
  display: flex;
  flex-direction: column;
}
.information-licence-container__title {
  font-weight: bold;
  margin-top: 25px;
  margin-bottom: 10px;
}
.information-licence-container__right {
  margin-bottom: 25px;
}

.birthplace-autocomplete {
  display: flex;
  align-items: center;
  grid-gap: 4px;
  gap: 4px;
}
.birthplace-autocomplete svg {
  margin-top: 36px;
}

@media (min-width: 992px) {
  .information-licence-container {
    flex-direction: row;
    align-items: center;
  }
  .information-licence-container__left {
    min-width: 400px;
    margin-right: 25px;
  }
  .information-licence-container__right {
    margin-left: 25px;
  }
}
.step-container {
  width: 92%;
  margin: auto;
  border: 1px solid #cccccc;
  margin-bottom: 10px;
}
.step-container .step-content {
  padding: 10px;
}
.step-container hr {
  border: none;
  border-bottom: 1px solid #cccccc;
  margin-top: 30px;
}

.intl-tel-input.allow-dropdown input {
  width: 272px;
}

@media (min-width: 768px) {
  .step-container {
    width: 100%;
    margin-bottom: 40px;
  }
  .step-container .step-content {
    padding: 40px;
  }
}
:root {
  --color-primary: #5ab11a;
  --color-secondary: #101e67;
}

/*
 * Screen breakpoints.
 */
/*
 * Other properties.
 */
/*
 * Fonts.
 */
@font-face {
  font-family: "BebasNeue";
  src: url("/assets/fonts/BebasNeue Regular.otf") format("truetype");
}
@font-face {
  font-family: "BebasNeue-Bold";
  src: url("/assets/fonts/BebasNeue Bold.ttf") format("truetype");
}
@font-face {
  font-family: "BebasNeue-Book";
  src: url("/assets/fonts/BebasNeue Book.otf") format("truetype");
}
@font-face {
  font-family: "BebasNeue-Light";
  src: url("/assets/fonts/BebasNeue Light.otf") format("truetype");
}
@font-face {
  font-family: "BebasNeue-Thin";
  src: url("/assets/fonts/BebasNeue Thin.otf") format("truetype");
}
.add-responsable-container {
  padding-top: 20px;
}
.add-responsable-container svg {
  margin-right: 10px;
}
.add-responsable-container span[role=button] {
  color: #5ab11a;
  color: var(--color-primary);
  transition: color 0.3s ease;
  cursor: pointer;
}
.add-responsable-container span[role=button]:hover {
  color: #101e67;
  color: var(--color-secondary);
}

.delete-responsable-button {
  margin-top: 10px;
}
:root {
  --color-primary: #5ab11a;
  --color-secondary: #101e67;
}

/*
 * Screen breakpoints.
 */
/*
 * Other properties.
 */
/*
 * Fonts.
 */
@font-face {
  font-family: "BebasNeue";
  src: url("/assets/fonts/BebasNeue Regular.otf") format("truetype");
}
@font-face {
  font-family: "BebasNeue-Bold";
  src: url("/assets/fonts/BebasNeue Bold.ttf") format("truetype");
}
@font-face {
  font-family: "BebasNeue-Book";
  src: url("/assets/fonts/BebasNeue Book.otf") format("truetype");
}
@font-face {
  font-family: "BebasNeue-Light";
  src: url("/assets/fonts/BebasNeue Light.otf") format("truetype");
}
@font-face {
  font-family: "BebasNeue-Thin";
  src: url("/assets/fonts/BebasNeue Thin.otf") format("truetype");
}
.certificate-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}
.certificate-container .left-side,
.certificate-container .right-side {
  padding: 10px;
}
.certificate-container .left-side {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}
.certificate-container .left-side img {
  width: 240px;
  border: 1px solid transparent;
  transition: border-color 0.3s ease;
  cursor: pointer;
}
.certificate-container .left-side img:hover {
  border-color: #5ab11a;
  border-color: var(--color-primary);
}
.certificate-container .small-text {
  font-size: 0.7rem;
}

@media (min-width: 768px) {
  .certificate-container {
    flex-direction: row;
  }
  .certificate-container .left-side,
.certificate-container .right-side {
    flex: 1 1;
    padding: 0 40px;
  }
  .certificate-container .left-side {
    flex-direction: row;
    border-right: 1px solid #cccccc;
  }
  .certificate-container .left-side img {
    margin-right: 20px;
  }
}
:root {
  --color-primary: #5ab11a;
  --color-secondary: #101e67;
}

/*
 * Screen breakpoints.
 */
/*
 * Other properties.
 */
/*
 * Fonts.
 */
@font-face {
  font-family: "BebasNeue";
  src: url("/assets/fonts/BebasNeue Regular.otf") format("truetype");
}
@font-face {
  font-family: "BebasNeue-Bold";
  src: url("/assets/fonts/BebasNeue Bold.ttf") format("truetype");
}
@font-face {
  font-family: "BebasNeue-Book";
  src: url("/assets/fonts/BebasNeue Book.otf") format("truetype");
}
@font-face {
  font-family: "BebasNeue-Light";
  src: url("/assets/fonts/BebasNeue Light.otf") format("truetype");
}
@font-face {
  font-family: "BebasNeue-Thin";
  src: url("/assets/fonts/BebasNeue Thin.otf") format("truetype");
}
.qssport-table {
  width: 100%;
  border-collapse: collapse;
}
.qssport-table thead tr th h4 {
  margin: 15px 0 10px 0;
}
.qssport-table tbody tr td {
  padding: 5px;
  border-bottom: 1px dashed #737373;
}
.qssport-table tbody tr td[colspan="3"] {
  background-color: #5ab11a;
  background-color: var(--color-primary);
  color: white;
  padding: 10px 5px;
  text-align: center;
  border-bottom: none;
}
.qssport-table tbody tr td.checkbox {
  text-align: center;
  width: 40px;
}
.qssport-table tbody tr td.background-transparent {
  background-color: transparent;
  color: #e2001a;
  font-style: italic;
}
.qssport-table tbody tr:hover {
  background-color: #e8e8e8;
}

.check-all {
  display: flex;
  justify-content: flex-end;
  color: #5ab11a;
  color: var(--color-primary);
  cursor: pointer;
  text-decoration: underline;
}

@media (min-width: 768px) {
  .qssport-table tbody tr td.checkbox {
    width: 50px;
  }
}
.display-file {
  margin-top: 0.5rem;
}
.display-file a {
  color: #cf2a1f;
}
:root {
  --color-primary: #5ab11a;
  --color-secondary: #101e67;
}

/*
 * Screen breakpoints.
 */
/*
 * Other properties.
 */
/*
 * Fonts.
 */
@font-face {
  font-family: "BebasNeue";
  src: url("/assets/fonts/BebasNeue Regular.otf") format("truetype");
}
@font-face {
  font-family: "BebasNeue-Bold";
  src: url("/assets/fonts/BebasNeue Bold.ttf") format("truetype");
}
@font-face {
  font-family: "BebasNeue-Book";
  src: url("/assets/fonts/BebasNeue Book.otf") format("truetype");
}
@font-face {
  font-family: "BebasNeue-Light";
  src: url("/assets/fonts/BebasNeue Light.otf") format("truetype");
}
@font-face {
  font-family: "BebasNeue-Thin";
  src: url("/assets/fonts/BebasNeue Thin.otf") format("truetype");
}
.total-price {
  background-color: #000;
  color: #fff;
  padding: 10px 20px;
  text-align: right;
  font-size: 25px;
  font-weight: 600;
}

@media (min-width: 768px) {
  .total-price {
    padding: 10px 50px 10px 0;
  }
}
:root {
  --color-primary: #5ab11a;
  --color-secondary: #101e67;
}

/*
 * Screen breakpoints.
 */
/*
 * Other properties.
 */
/*
 * Fonts.
 */
@font-face {
  font-family: "BebasNeue";
  src: url("/assets/fonts/BebasNeue Regular.otf") format("truetype");
}
@font-face {
  font-family: "BebasNeue-Bold";
  src: url("/assets/fonts/BebasNeue Bold.ttf") format("truetype");
}
@font-face {
  font-family: "BebasNeue-Book";
  src: url("/assets/fonts/BebasNeue Book.otf") format("truetype");
}
@font-face {
  font-family: "BebasNeue-Light";
  src: url("/assets/fonts/BebasNeue Light.otf") format("truetype");
}
@font-face {
  font-family: "BebasNeue-Thin";
  src: url("/assets/fonts/BebasNeue Thin.otf") format("truetype");
}
.under-footer-text {
  text-align: center;
  font-weight: 500;
  margin-top: 10px;
}

.licence-renewal-footer span {
  text-align: center;
}
.licence-renewal-footer span button {
  margin-top: 20px;
}
.licence-renewal-footer span button:first-child {
  margin-top: initial;
}

@media (min-width: 768px) {
  .under-footer-text {
    text-align: right;
    margin-top: initial;
  }
}
:root {
  --color-primary: #5ab11a;
  --color-secondary: #101e67;
}

/*
 * Screen breakpoints.
 */
/*
 * Other properties.
 */
/*
 * Fonts.
 */
@font-face {
  font-family: "BebasNeue";
  src: url("/assets/fonts/BebasNeue Regular.otf") format("truetype");
}
@font-face {
  font-family: "BebasNeue-Bold";
  src: url("/assets/fonts/BebasNeue Bold.ttf") format("truetype");
}
@font-face {
  font-family: "BebasNeue-Book";
  src: url("/assets/fonts/BebasNeue Book.otf") format("truetype");
}
@font-face {
  font-family: "BebasNeue-Light";
  src: url("/assets/fonts/BebasNeue Light.otf") format("truetype");
}
@font-face {
  font-family: "BebasNeue-Thin";
  src: url("/assets/fonts/BebasNeue Thin.otf") format("truetype");
}
.modalites-container {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: top;
}
.modalites-container .motalites-versements {
  display: flex;
  flex-direction: column;
  background-color: whitesmoke;
  padding: 10px;
  margin-top: 15px;
}

@media (min-width: 576px) {
  .modalites-container {
    flex-direction: row;
  }
  .modalites-container .motalites-versements {
    padding: 30px;
    margin-top: initial;
  }
}
:root {
  --color-primary: #5ab11a;
  --color-secondary: #101e67;
}

/*
 * Screen breakpoints.
 */
/*
 * Other properties.
 */
/*
 * Fonts.
 */
@font-face {
  font-family: "BebasNeue";
  src: url("/assets/fonts/BebasNeue Regular.otf") format("truetype");
}
@font-face {
  font-family: "BebasNeue-Bold";
  src: url("/assets/fonts/BebasNeue Bold.ttf") format("truetype");
}
@font-face {
  font-family: "BebasNeue-Book";
  src: url("/assets/fonts/BebasNeue Book.otf") format("truetype");
}
@font-face {
  font-family: "BebasNeue-Light";
  src: url("/assets/fonts/BebasNeue Light.otf") format("truetype");
}
@font-face {
  font-family: "BebasNeue-Thin";
  src: url("/assets/fonts/BebasNeue Thin.otf") format("truetype");
}
.methods-container {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}
.methods-container .method {
  width: 150px;
  padding: 25px;
  margin-top: 5px;
  background-color: white;
  border: 1px solid #cccccc;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: all 0.3s ease;
  cursor: pointer;
}
.methods-container .method:first-child {
  margin-top: initial;
}
.methods-container .method svg {
  margin-bottom: 10px;
}
.methods-container .method.selected {
  border: 2px solid #5ab11a;
  border: 2px solid var(--color-primary);
  font-weight: bold;
  color: #5ab11a;
  color: var(--color-primary);
}
.methods-container .method:hover {
  border-color: #5ab11a;
  border-color: var(--color-primary);
}

.secure-text {
  margin-top: 20px;
}

@media (min-width: 576px) {
  .methods-container {
    flex-direction: row;
  }
  .methods-container .method {
    width: 200px;
    padding: 40px;
    margin-top: initial;
  }
}
:root {
  --color-primary: #5ab11a;
  --color-secondary: #101e67;
}

/*
 * Screen breakpoints.
 */
/*
 * Other properties.
 */
/*
 * Fonts.
 */
@font-face {
  font-family: "BebasNeue";
  src: url("/assets/fonts/BebasNeue Regular.otf") format("truetype");
}
@font-face {
  font-family: "BebasNeue-Bold";
  src: url("/assets/fonts/BebasNeue Bold.ttf") format("truetype");
}
@font-face {
  font-family: "BebasNeue-Book";
  src: url("/assets/fonts/BebasNeue Book.otf") format("truetype");
}
@font-face {
  font-family: "BebasNeue-Light";
  src: url("/assets/fonts/BebasNeue Light.otf") format("truetype");
}
@font-face {
  font-family: "BebasNeue-Thin";
  src: url("/assets/fonts/BebasNeue Thin.otf") format("truetype");
}
.completed-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 85%;
  margin: auto;
}
.completed-container .completed-title,
.completed-container p {
  text-align: center;
}
.completed-container .completed-title {
  margin-top: 0;
}
.completed-container p {
  color: #343a40;
  font-size: 18px;
  margin: 0;
}
.completed-container img {
  width: 50%;
  margin: 20px 0;
}
.completed-container button {
  padding: 10px 20px;
}

@media (min-width: 576px) {
  .completed-container {
    width: initial;
    margin: initial;
  }
  .completed-container .completed-title {
    margin-top: initial;
  }
  .completed-container img {
    width: 400px;
    margin: 40px 0;
  }
}
:root {
  --color-primary: #5ab11a;
  --color-secondary: #101e67;
}

/*
 * Screen breakpoints.
 */
/*
 * Other properties.
 */
/*
 * Fonts.
 */
@font-face {
  font-family: "BebasNeue";
  src: url("/assets/fonts/BebasNeue Regular.otf") format("truetype");
}
@font-face {
  font-family: "BebasNeue-Bold";
  src: url("/assets/fonts/BebasNeue Bold.ttf") format("truetype");
}
@font-face {
  font-family: "BebasNeue-Book";
  src: url("/assets/fonts/BebasNeue Book.otf") format("truetype");
}
@font-face {
  font-family: "BebasNeue-Light";
  src: url("/assets/fonts/BebasNeue Light.otf") format("truetype");
}
@font-face {
  font-family: "BebasNeue-Thin";
  src: url("/assets/fonts/BebasNeue Thin.otf") format("truetype");
}
.completed-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 85%;
  margin: auto;
}
.completed-container .completed-title,
.completed-container p {
  text-align: center;
}
.completed-container .completed-title {
  margin-top: 0;
}
.completed-container p {
  color: #343a40;
  font-size: 18px;
  margin: 0;
}
.completed-container img {
  width: 50%;
  margin: 20px 0;
}
.completed-container button {
  padding: 10px 20px;
}

@media (min-width: 576px) {
  .completed-container {
    width: initial;
    margin: initial;
  }
  .completed-container .completed-title {
    margin-top: initial;
  }
  .completed-container img {
    width: 400px;
    margin: 40px 0;
  }
}
.licence-renewal-container main {
  width: 100%;
}

@media (min-width: 576px) {
  .licence-renewal-container main {
    margin: auto;
    max-width: 70%;
  }
}
.social-list-pictogram {
  display: flex;
  list-style: none;
  margin-top: 0;
  margin-bottom: 0;
  padding: 0;
}

.social-list-item {
  display: flex;
  margin: 0 10px;
  color: #fff;
}
.social-list-item a {
  color: var(--white);
  display: flex;
  align-items: center;
  text-decoration: none;
}
.social-list-item a:hover {
  color: var(--color-primary);
  text-decoration: none;
}
.social-list-item a:hover svg {
  margin-left: 0;
}
.social-list-item a svg {
  font-size: 1.3rem;
  transition: none;
}

@media screen and (max-width: 1100px) {
  .social-list-item {
    margin: 0 8px;
  }
}
:root {
  --color-primary: #5ab11a;
  --color-secondary: #101e67;
}

/*
 * Screen breakpoints.
 */
/*
 * Other properties.
 */
/*
 * Fonts.
 */
@font-face {
  font-family: "BebasNeue";
  src: url("/assets/fonts/BebasNeue Regular.otf") format("truetype");
}
@font-face {
  font-family: "BebasNeue-Bold";
  src: url("/assets/fonts/BebasNeue Bold.ttf") format("truetype");
}
@font-face {
  font-family: "BebasNeue-Book";
  src: url("/assets/fonts/BebasNeue Book.otf") format("truetype");
}
@font-face {
  font-family: "BebasNeue-Light";
  src: url("/assets/fonts/BebasNeue Light.otf") format("truetype");
}
@font-face {
  font-family: "BebasNeue-Thin";
  src: url("/assets/fonts/BebasNeue Thin.otf") format("truetype");
}
.pre-footer {
  background-image: url("/assets/images/footer.jpg");
  background-size: cover;
  background-repeat: no-repeat;
}

.pre-footer-wrapper {
  display: flex;
  justify-content: space-evenly;
  padding: 20px;
}

.pre-footer-item {
  color: #fff;
  font-family: "Open Sans", sans-serif;
  display: flex;
}

.pre-footer-content-wrapper {
  display: flex;
  flex-direction: column;
  margin-left: 8px;
}

.pre-footer-content {
  list-style: none;
  margin: 0;
  padding: 0;
  font-size: 14px;
  font-weight: 400;
}
.pre-footer-content a {
  all: unset;
  color: #fff;
  text-decoration: underline;
}
.pre-footer-content a:hover {
  color: #fff;
  cursor: pointer;
}

.pre-footer-title {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}
.pre-footer-title p {
  font-family: "BebasNeue-Bold";
  text-transform: uppercase;
  color: #5ab11a;
  color: var(--color-primary);
  margin: 0;
  font-size: 20px;
}

@media screen and (max-width: 768px) {
  .pre-footer {
    display: flex;
    justify-content: center;
  }
  .pre-footer-wrapper {
    flex-direction: column;
    align-items: flex-start;
    padding-bottom: 0;
  }
  .pre-footer-item {
    margin-bottom: 20px;
  }
}
.footer-background {
  background-image: url("/assets/images/footer.jpg");
  background-size: cover;
  background-repeat: no-repeat;
}

.footer-wrapper {
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-around;
  align-items: center;
  padding: 1.57rem;
}

.footer-link {
  list-style: none;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  margin: 0;
  padding: 0;
}

.footer-link-item:not(:last-of-type) {
  border-right: 1px solid var(--color-primary);
}

.footer-link-item a {
  display: inline-block;
  color: white;
  font-family: "Open Sans", sans-serif;
  font-size: 0.94rem;
  padding: 5px 20px;
}

.footer-link-item:hover a {
  color: var(--color-primary);
}

.footer-address {
  margin-bottom: 40px;
  margin-top: 0;
  font-size: 1rem;
  color: #fff;
  text-align: center;
}

.footer-phone-number {
  display: inline-block;
  color: var(--color-primary);
}

.footer-phone-number:hover {
  color: var(--color-primary);
}

.brand-footer-logo {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

@media screen and (max-width: 768px) {
  .footer-wrapper {
    padding: 0;
  }
  .social-link {
    order: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1.87rem 0;
  }
  .footer-wrapper > * {
    margin: 0;
    width: 100%;
  }
  .footer-link {
    flex-direction: column;
    align-items: center;
    order: 2;
    padding: 2rem 0;
    border-top: 1px solid var(--color-primary);
    border-bottom: 1px solid var(--color-primary);
  }
  .footer-link-item:not(:last-of-type) {
    border-right: none;
    margin-bottom: 10px;
  }
  .brand-footer-logo {
    order: 3;
    text-align: center;
    padding: 1.25rem 0;
  }
  .footer-address {
    order: 4;
    padding: 1.25rem 0;
  }
  .footer-phone-number {
    margin: 10px 0;
    font-size: 1.25rem;
  }
}
:root {
  --color-primary: #5ab11a;
  --color-secondary: #101e67;
}

/*
 * Screen breakpoints.
 */
/*
 * Other properties.
 */
/*
 * Fonts.
 */
@font-face {
  font-family: "BebasNeue";
  src: url("/assets/fonts/BebasNeue Regular.otf") format("truetype");
}
@font-face {
  font-family: "BebasNeue-Bold";
  src: url("/assets/fonts/BebasNeue Bold.ttf") format("truetype");
}
@font-face {
  font-family: "BebasNeue-Book";
  src: url("/assets/fonts/BebasNeue Book.otf") format("truetype");
}
@font-face {
  font-family: "BebasNeue-Light";
  src: url("/assets/fonts/BebasNeue Light.otf") format("truetype");
}
@font-face {
  font-family: "BebasNeue-Thin";
  src: url("/assets/fonts/BebasNeue Thin.otf") format("truetype");
}
:root {
  --color-primary: #5ab11a;
  --color-secondary: #101e67;
}

/*
 * Screen breakpoints.
 */
/*
 * Other properties.
 */
/*
 * Fonts.
 */
@font-face {
  font-family: "BebasNeue";
  src: url("/assets/fonts/BebasNeue Regular.otf") format("truetype");
}
@font-face {
  font-family: "BebasNeue-Bold";
  src: url("/assets/fonts/BebasNeue Bold.ttf") format("truetype");
}
@font-face {
  font-family: "BebasNeue-Book";
  src: url("/assets/fonts/BebasNeue Book.otf") format("truetype");
}
@font-face {
  font-family: "BebasNeue-Light";
  src: url("/assets/fonts/BebasNeue Light.otf") format("truetype");
}
@font-face {
  font-family: "BebasNeue-Thin";
  src: url("/assets/fonts/BebasNeue Thin.otf") format("truetype");
}
.form-flex-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
}
.form-flex-container .form-flex-item {
  margin-right: 10px;
}
.form-flex-container .form-flex-item:last-child {
  margin-right: 0;
}

html {
  font-size: 100%;
} /*16px*/
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  justify-content: center;
  max-width: 2000px;
  margin: auto;
  overflow-x: hidden;
}

/* VISIBLE */
.visible-xs {
  display: initial;
}

@media (min-width: 576px) {
  .visible-xs {
    display: none;
  }
}
@media (min-width: 768px) {
  .visible-xs {
    display: none;
  }
}
@media (min-width: 992px) {
  .visible-xs {
    display: none;
  }
}
@media (min-width: 1200px) {
  .visible-xs {
    display: none;
  }
}
.primary {
  color: #5ab11a;
  color: var(--color-primary);
}

.secondary {
  color: #101e67;
  color: var(--color-secondary);
}

.danger {
  color: #e2001a;
}

.bg-primary {
  background-color: #5ab11a;
  background-color: var(--color-primary);
}

.bg-secondary {
  background-color: #101e67;
  background-color: var(--color-secondary);
}

.bg-danger {
  background-color: #e2001a;
}

a,
.link {
  color: #5ab11a;
  color: var(--color-primary);
  transition: color 0.3s ease;
  text-decoration: none;
  cursor: pointer;
}
a svg,
.link svg {
  transition: margin 0.3s ease;
}
a:hover,
.link:hover {
  color: #101e67;
  color: var(--color-secondary);
  text-decoration: underline;
}
a:hover svg,
.link:hover svg {
  margin-left: 5px;
}

/* H */
h1,
h2,
h3,
h4,
h5 {
  margin: 2.75rem 0 1.05rem;
  font-family: "BebasNeue-Bold";
  font-weight: 400;
  line-height: 1.15;
  color: #150d26;
}

h1 {
  margin: 0;
  font-size: 3.052em;
}

h2 {
  font-size: 2.441em;
}

h3 {
  font-size: 1.953em;
}

h4 {
  font-size: 1.563em;
}

h5 {
  font-size: 1.25em;
}

small,
.text_small {
  font-size: 0.8em;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
}

.no-margin {
  margin: 0;
}

.wrap-long-text {
  overflow-wrap: break-word;
  word-wrap: break-word;
  -ms-word-break: break-all;
  /* Instead use this non-standard one: */
  word-break: break-word;
  /* Adds a hyphen where the word breaks, if supported (No Blink) */
  -webkit-hyphens: auto;
  hyphens: auto;
}

.text-error {
  color: #e2001a;
}

.text-grey {
  color: #737373;
}

.u-bold {
  font-weight: bold;
}
