@import "assets/css/variables.scss";

.reductions-options-container {
  &--item {
    display: flex;
    flex-direction: row;
    align-items: center;

    & > div {
      &:nth-child(1) {
        flex: 6;
      }

      &:not(:nth-child(1)) {
        flex: 1;
      }
    }
  }

  &--info {
    margin-left: 10px;
    color: $color-primary;
  }
}
