$gutter: 15px;
$padding: calc($gutter / 1.5);

.display-proofs {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;

  &__item {
    position: relative;
    display: flex;
    flex-direction: column;
    border: 1px solid $color-grey;
    padding: $padding;
    margin-top: $gutter;

    &[data-has-file="false"] {
      cursor: pointer;
    }

    &::before {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-color: $color-primary;
      opacity: 0;
      z-index: -1;
      transition: all 0.5s;
    }

    &[data-has-file="false"]:hover::before {
      opacity: 0.3;
    }

    &:not(:last-child) {
      margin-right: $gutter;
    }

    p {
      margin: 0;
      min-height: 42px;
    }
  }

  &--name {
    font-family: $font-bebas;
    margin: 0;

    small {
      color: $color-danger;
      font-size: 15px;
    }
  }

  &--description {
    margin-bottom: 10px;
  }

  &--file {
    display: none;
  }

  &--add-button {
    display: inline-block;
    text-align: center;
    width: 25px;
    height: 25px;
    line-height: 25px;
    text-align: center;
    background-color: $color-primary;
    color: $color-white;
    border-radius: 25px;
    margin: auto;
  }

  &--remove-button {
    text-align: center;
    color: $color-danger;
    cursor: pointer;
  }

  &--uploaded-file {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
  }
}

@media (min-width: $breakpoint-sm) {
  .display-proofs {
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    &__item {
      width: 33%;
    }
  }
}
