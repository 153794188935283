%border-bottom {
  border-bottom: 1px solid $color-grey-dark;
}

.display-pricing {
  &--price-item {
    font-size: 18px;

    .flex-container {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 10px 15px;
      @extend %border-bottom;

      & > div:nth-child(1) {
        flex: 6;
        font-weight: bold;
      }

      & > div:nth-child(2) {
        flex: 1;
      }

      & > div:nth-child(3) {
        flex: 1;
        text-align: center;
      }
    }

    .reductions-options-container {
      overflow: hidden;

      .content {
        transition: all 0.3s;
        padding: 25px;
        background-color: $color-grey-lighter;
        @extend %border-bottom;

        div:last-child {
          h4 {
            margin-top: 25px;
          }
        }

        h4,
        h5 {
          margin: 0;
        }

        h5 {
          font-size: 20px;
          margin-bottom: 10px;
        }
      }

      &[data-visible="true"] {
        .content {
          margin: 0;
        }
      }

      &[data-visible="false"] {
        .content {
          margin-top: -100%;
        }
      }
    }
  }

  &--price-item-description {
    font-size: 14px;
    font-weight: initial;
  }

  .file-container {
    display: flex;
    flex-direction: row;
    align-items: center;

    &--text {
      padding-right: 5px;
      color: $color-danger;
    }
  }
}
