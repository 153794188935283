@import "assets/css/variables.scss";

.completed-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 85%;
  margin: auto;

  .completed-title,
  p {
    text-align: center;
  }

  .completed-title {
    margin-top: 0;
  }

  p {
    color: #343a40;
    font-size: 18px;
    margin: 0;
  }

  img {
    width: 50%;
    margin: 20px 0;
  }

  button {
    padding: 10px 20px;
  }
}

// Small devices (landscape phones, 576px and up)
@media (min-width: $breakpoint-sm) {
  .completed-container {
    width: initial;
    margin: initial;

    .completed-title {
      margin-top: initial;
    }

    img {
      width: 400px;
      margin: 40px 0;
    }
  }
}
