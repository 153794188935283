@import "./variables.scss";
@import "./form.scss";

html {
  font-size: 100%;
} /*16px*/

body {
  margin: 0;
  font-family: $font-default;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  justify-content: center;
  max-width: 2000px;
  margin: auto;

  overflow-x: hidden;
}

/* VISIBLE */
// XS
.visible-xs {
  display: initial;
}

// Small devices (landscape phones, 576px and up)
@media (min-width: $breakpoint-sm) {
  .visible-xs {
    display: none;
  }
}

// Medium devices (tablets, 768px and up)
@media (min-width: $breakpoint-md) {
  .visible-xs {
    display: none;
  }
}

// Large devices (desktops, 992px and up)
@media (min-width: $breakpoint-lg) {
  .visible-xs {
    display: none;
  }
}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: $breakpoint-xl) {
  .visible-xs {
    display: none;
  }
}

.primary {
  color: $color-primary;
}

.secondary {
  color: $color-secondary;
}

.danger {
  color: $color-danger;
}

.bg-primary {
  background-color: $color-primary;
}

.bg-secondary {
  background-color: $color-secondary;
}

.bg-danger {
  background-color: $color-danger;
}

a,
.link {
  color: $color-primary;
  transition: color 0.3s ease;
  text-decoration: none;
  cursor: pointer;

  svg {
    transition: margin 0.3s ease;
  }

  &:hover {
    color: $color-secondary;
    text-decoration: underline;

    svg {
      margin-left: 5px;
    }
  }
}

/* H */
// XS
h1,
h2,
h3,
h4,
h5 {
  margin: 2.75rem 0 1.05rem;
  font-family: "BebasNeue-Bold";
  font-weight: 400;
  line-height: 1.15;
  color: $color-title;
}

h1 {
  margin: 0;
  font-size: 3.052em;
}

h2 {
  font-size: 2.441em;
}

h3 {
  font-size: 1.953em;
}

h4 {
  font-size: 1.563em;
}

h5 {
  font-size: 1.25em;
}

small,
.text_small {
  font-size: 0.8em;
  font-family: $font-default;
}

.no-margin {
  margin: 0;
}

.wrap-long-text {
  overflow-wrap: break-word;
  word-wrap: break-word;

  -ms-word-break: break-all;
  /* Instead use this non-standard one: */
  word-break: break-word;

  /* Adds a hyphen where the word breaks, if supported (No Blink) */
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;
}

.text-error {
  color: $color-danger;
}

.text-grey {
  color: $color-grey;
}

.u-bold {
  font-weight: bold;
}
