@import "assets/css/variables.scss";

.form-flex-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;

  .form-flex-item {
    margin-right: 10px;

    &:last-child {
      margin-right: 0;
    }
  }
}
